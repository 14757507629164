import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import DataBekant from "../data/bekant"
import DataDesktopia from "../data/desktopia"
import DataEadjust from "../data/eadjust"
import DataExeta from "../data/exeta-tischgestell"
import DataFlexispot from "../data/flexispot-h2w";
import DataImperioLift from "../data/imperio-lift";
import DataMasterlift2 from "../data/masterlift2";
import DataOvos196 from "../data/o-vos19-6";
import DataSkarsta from "../data/skarsta";
import DataTopEco from "../data/top-eco";
import DataXbhm from "../data/xbhm";
import DataSongmics from "../data/songmics.json"

const Vergleich = () => {
  const products = [
    DataBekant,
    DataSkarsta,
    DataFlexispot,
    DataEadjust,
    DataExeta,
    DataMasterlift2,
    DataImperioLift,
    DataDesktopia,
    DataTopEco,
    DataOvos196,
    DataXbhm,
    DataSongmics,
  ]

  return (
    <>
      <Layout>
        <SEO
          title="8 Höhenverstellbare Schreibtische/Stehschreibtische im Vergleich"
          description="Wir haben 9 Höhenverstellbare Schreibtische/Stehschreibtische verglichen. Alle Fakten in der Übersicht."
        />
        <div className="jumbotron jumbotron-fluid">
          <div className="container">
            <h1 className="display-3">Vergleich</h1>
            <p className="lead">Wir haben recherchiert und dir die wichtigsten Informationen zu ausgewählten Stehschreibtischen in eine Vergleichstabelle gepackt.</p>
          </div>
        </div>

        <div className="container">
          <table className="table table-striped table-bordered table-responsive-md table-sm">
            <thead>
              <tr>
                <th></th>
                {
                  products.map((p) => { return (<th className="align-text-top"><a href={p.affiliateLink}>{p.name}</a></th>) })
                }
              </tr>
            </thead>
            <tr className="table-primary">
              <th>Bewertung</th>
              {
                products.map((p) => { return (<td>{p.rating} <span role="img" aria-label="Bewertung">⭐</span></td>) })
              }
            </tr>
            <tr>
              <th>Preis</th>
              {
                products.map((p) => { return (<td>€ {p.price}</td>) })
              }
            </tr>
            <tr>
              <th></th>
              {
                products.map((p) => { return (<td><a href={p.affiliateLink} className="btn btn-sm btn-success">Zum Angebot</a></td>) })
              }
            </tr>
            <tr>
              <th>Hersteller</th>
              {
                products.map((p) => { return (<td>{p.brand}</td>) })
              }
            </tr>
            <tr>
              <th>Garantie</th>
              {
                products.map((p) => { return (<td>{p.warranty} Jahre</td>) })
              }
            </tr>
            <tr>
              <th>Höhe</th>
              {
                products.map((p) => { return (<td>{p.heightMax} - {p.heightMin} cm</td>) })
              }
            </tr>
            <tr>
              <th>Maße</th>
              {
                products.map((p) => { return (<td>{p.dimensions}</td>) })
              }
            </tr>
            <tr>
              <th>Tragfähigkeit</th>
              {
                products.map((p) => { return (<td>{p.weightLimit}</td>) })
              }
            </tr>
            <tr>
              <th>Antrieb</th>
              {
                products.map((p) => { return (<td>{p.adjustingDrive}</td>) })
              }
            </tr>
            <tr>
              <th>Gimmicks</th>
              {
                products.map((p) => { return (<td>{p.gimmicks}</td>) })
              }
            </tr>
            <tr>
              <th>Gewicht</th>
              {
                products.map((p) => { return (<td>{p.weight} kg</td>) })
              }
            </tr>
            <tr>
              <th>Kollisionsschutz</th>
              {
                products.map((p) => { return (<td>{p.collisionProtection}</td>) })
              }
            </tr>
            <tr>
              <th>Anmerkung</th>
              {
                products.map((p) => { return (<td>{p.notes}</td>) })
              }
            </tr>
          </table>
          <p>Viele Tische sind auch ohne Tischplatte erhältlich.</p>
        </div>
      </Layout>
    </>
  )
}

export default Vergleich
